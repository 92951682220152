import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/*  *** 分组管理 ****** */

// 列表
export async function getGroups(params, pager = { siez: PAGE_SIZE, page: 1 }) {
  try {
    return await API.get("groupManage", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

// 父群组
export async function getParentGroups(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("groupManage/parent", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

// 活跃设备根据登进来角色查询子群
export async function getAssignGroups(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("device/findbyMobile", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

// 增
export async function addGroups(params) {
  try {
    return await API.post("groupManage", params);
  } catch (error) {
    return error;
  }
}

// 更
export async function updateGroups(params) {
  try {
    return await API.post("groupManage/update", params);
  } catch (error) {
    return error;
  }
}

// 删
export async function delGroups(ids) {
  try {
    return await API.del("groupManage/" + ids, { ids });
  } catch (error) {
    return error;
  }
}
