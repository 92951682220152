<style lang="less" scoped>
</style>

<template>
  <el-form ref="editForm" :rules="rules" :model="info" label-width="110px">
    <el-form-item label="一层滤网：">
      <!-- <el-input v-model="info.filter1" type="number"></el-input> -->
      <el-input-number
        v-model="info.filter1"
        :min="0"
        :max="info.filter1MaxValue"
        style="width:100%"
      ></el-input-number>
    </el-form-item>

    <el-form-item label="二层滤网：">
      <el-input-number
        v-model="info.filter2"
        :min="0"
        :max="info.filter2MaxValue"
        style="width:100%"
      ></el-input-number>
    </el-form-item>

    <el-form-item label="三层滤网：">
      <el-input-number
        v-model="info.filter3"
        :min="0"
        :max="info.filter3MaxValue"
        style="width:100%"
      ></el-input-number>
    </el-form-item>

    <el-form-item label="四层滤网：">
      <el-input-number
        v-model="info.filter4"
        :min="0"
        :max="info.filter4MaxValue"
        style="width:100%"
      ></el-input-number>
    </el-form-item>

    <el-form-item class="text-right m-t-md">
      <el-button type="primary" size="large" @click="submit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { updateGroups, addGroups, getGroups } from "../../services/group";
import { Notification } from "element-ui";
import { searchUser } from "../../services/user";
import { surplusCheck } from "../../services/device";
import { valiPhone } from "../../utils/validator/element";
export default {
  data() {
    return {
      groupList: [],
      userList: [],
      info: {
        filter1: "",
        filter2: "",
        filter3: ""
      },
      rules: {
        filter1: [{ required: true, message: "请输入", trigger: "blur" }],
        filter2: [{ required: true, message: "请输入", trigger: "blur" }],
        filter3: [{ required: true, message: "请输入", trigger: "blur" }]
      }
    };
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    detailVisible: {
      type: Boolean,
      default: false
    },
    propInfo: {
      type: Object,
      default() {
        return {
          filter1: "",
          filter2: "",
          filter3: ""
        };
      }
    }
  },
  methods: {
    submit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.update();
        }
      });
    },
    async update() {
      const params = {
        mac: this.info.mac,
        filter1: this.info.filter1,
        filter2: this.info.filter2,
        filter3: this.info.filter3
      };
      const res = await surplusCheck(params);
      if (res.errorCode === 0) {
        if (res.data === "done") {
          this.$emit("onSubmit");
          Notification.success({
            title: "成功",
            message: "发送命令成功,结果将刷新"
          });
        } else {
          Notification.error({
            title: "错误",
            message: "发送命令失败"
          });
        }
      }
    },

    async queryList(keys) {
      const responseData = await getGroups({ keys });
      if (responseData && responseData.data && responseData.errorCode === 0) {
        this.groupList = responseData.data.datas || [];
      }
    },

    async queryUsers(filter2) {
      const responseData = await searchUser({ filter2 });
      if (responseData && responseData.data && responseData.errorCode === 0) {
        this.userList = responseData.data.datas || [];
      }
    },

    initData() {
      if (this.isEdit) {
        this.info = Object.assign({}, this.propInfo);
        console.log(this.propInfo);
        console.log(this.info);
      }
    }
  },
  created() {
    this.queryList();
    this.queryUsers();
    this.initData();
  },
  watch: {
    detailVisible(newval) {
      if (newval) {
        this.initData();
      } else {
        this.info = {
          filter1: "",
          filter2: "",
          filter3: ""
        };
      }
    }
  }
};
</script>