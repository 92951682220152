import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/*  *** 分组管理 ****** */

// 列表
export async function getGroups(params, pager = { siez: PAGE_SIZE, page: 1 }) {
  try {
    return await API.get("surplusManage", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

// 增
export async function addGroups(params) {
  try {
    return await API.post("groupManage", params);
  } catch (error) {
    return error;
  }
}

// 更
export async function updateGroups(params) {
  try {
    return await API.post("groupManage/update", params);
  } catch (error) {
    return error;
  }
}

// 删
export async function delGroups(ids) {
  try {
    return await API.del("groupManage/" + ids, { ids });
  } catch (error) {
    return error;
  }
}
