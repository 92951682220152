<style lang="less" scoped>
  .content {
    margin-bottom: 30px !important;
    .list-content {
      margin-bottom: 50px;
      padding: 30px;
      .toolbar {
        padding-top: 20px;
      }
    }
  }
  #setDeviceShow .el-checkbox {
    margin: 10px 15px 15px 0;
  }
  #setDeviceShow .el-checkbox + .el-checkbox {
    margin: 10px 15px 15px 0;
  }
</style>
<template>
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: this.$route.path }">
        滤网重置
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix" v-on:keyup.13="handleSearch">
      <!--查询筛选-->
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input
            style="width: 240px;"
            v-model="filters.day"
            clearable
            type="number"
            placeholder="滤网报警剩余小时数"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <!-- <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="复选框 A"></el-checkbox>
            <el-checkbox label="复选框 B"></el-checkbox>
            <el-checkbox label="复选框 C"></el-checkbox>
            <el-checkbox label="禁用" disabled></el-checkbox>
            <el-checkbox label="选中且禁用" disabled></el-checkbox>
          </el-checkbox-group>
        </div> -->
      </el-form>

      <el-table
        :data="dataList"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>

        <el-table-column align="center" label="序号" min-width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="sn" label="编号"></el-table-column>

        <el-table-column prop="mac" label="MAC"></el-table-column>

        <el-table-column prop="name" label="设备名称"></el-table-column>

        <el-table-column
          prop="groupManageName"
          label="所属群组"
        ></el-table-column>

        <el-table-column
          prop="filter1"
          align="center"
          width="100"
          label="一层滤芯剩余 (H)"
        ></el-table-column>

        <el-table-column
          prop="filter2"
          align="center"
          width="100"
          label="二层滤芯剩余 (H)"
        ></el-table-column>

        <el-table-column
          prop="filter3"
          align="center"
          width="100"
          label="三层滤芯剩余 (H)"
        ></el-table-column>

        <el-table-column
          prop="filter3"
          align="center"
          width="100"
          label="四层滤芯剩余 (H)"
        ></el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          width="160"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column width="160" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleReset(scope.row)"
            >
              重置
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-col :span="24" class="bottom-tool-container">
        <!--分页-->
        <el-pagination
          @size-change="pageSizeChange"
          @current-change="pageCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="isEdit ? '重置' : '批量重置'"
      size="small"
      :visible.sync="detailVisible"
    >
      <detail
        :propInfo="choosedRow"
        :detailVisible="detailVisible"
        :isEdit="isEdit"
        v-on:onSubmit="onSubmit"
      ></detail>
    </el-dialog>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import { PAGE_SIZE } from "../../config";
  import { Notification } from "element-ui";
  import { getGroups, delGroups } from "../../services/filter";
  import detail from "./detail.vue";
  import moment from "moment";

  export default {
    data() {
      return {
        isShow: false,
        editPermission: true,
        isEdit: false,
        filterBigScreenList: [],
        bigScreenList: [],
        dataList: [],
        filters: {
          day: "",
        },
        PAGE_SIZE,
        pager: {
          page: 1,
          size: PAGE_SIZE,
        },
        total: 0,
        listLoading: false,
        detailVisible: false, //新增
        deviceDialogVisible: false, //设备显示情况
        choosedRowIndex: 0,
        setShow: {},
        choosedRow: {},
        sels: [], //列表选中列
        permissionIds: [],
        userAllDeviceList: [], //查询该用户对应的所有设备
        userToShowDdeviceList: [], //查询该用户已经设置要显示的设备
        deviceIds: [], //查询该用户的设备id数组
        userId: "",
        userInfos: {},
        wechatUsers: [], //微信用户
        updataInfo: {
          //用来专门存放修改后的数据，作为数据存传回后台做修改
          id: "", //
          userId: "", //
          userName: "", //客户名称
          title: "", // 空气道名称
          logoUrl: "", //logo图片
          imageUrl: "", // 横屏图片
          verticalImageUrl: "", // 竖屏图片
          pollingTime: "", // 轮播时间
        },
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    components: {
      // 'update-view': updateBigScreenView,
      detail,
    },
    filters: {
      formatTime(row) {
        return row ? moment(row).format("YYYY-MM-DD") : "";
      },
    },
    methods: {
      handleReset(row) {
        this.isEdit = true;
        this.choosedRow = Object.assign({}, row);
        this.detailVisible = true;
      },

      handleSearch() {
        this.pager.page = 1;
        this.queryList();
      },

      /**
       * 单条数据的删除
       */
      // handleDel: function(index, row) {
      //   this.$confirm("确认删除该记录吗?", "提示", {
      //     type: "warning"
      //   })
      //     .then(() => {
      //       this.listLoading = true;
      //       delGroups(row.id).then(res => {
      //         this.listLoading = false;
      //         Notification.success({
      //           title: "成功",
      //           message: "删除成功"
      //         });
      //         this.queryList();
      //       });
      //     })
      //     .catch(() => {});
      // },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 设置分页的size
       */
      pageSizeChange(val) {
        //    	 console.log(`每页 ${val} 条`);
        this.pager.size = val;
        this.pager.page = 1;
        this.queryList();
      },
      /**
       *  翻页
       */
      pageCurrentChange(page) {
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({
            path: this.$route.path,
            query: { page: page },
          });
          this.queryList();
        }
      },

      // 列表
      async queryList() {
        this.listLoading = true;
        const responseData = await getGroups(this.filters, this.pager);
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.dataList = responseData.data.datas || [];
          this.total = responseData.data.totalCount;
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       *用户修改或者新增成功
       * @param isCreate true 创建  false 修改
       */
      onSubmit(roleData) {
        this.detailVisible = false;
        setTimeout(() => {
          this.queryList();
        }, 500);
      },
      /**
       * 点击按钮跳转到配置编辑
       */
      // async handleEdit(index, row) {
      //   this.isEdit = true;
      //   this.choosedRowIndex = index;
      //   this.choosedRow = Object.assign({}, row);
      //   this.detailVisible = true
      // }
    },
    created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }

      this.queryList();
    },

    watch: {
      detailVisible(newVal) {
        if (newVal === false) {
          this.isEdit = false;
        }
      },
    },
  };
</script>
